import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import 'virtual:vite-plugin-sentry/sentry-config';
import * as Sentry from '@sentry/vue';

import {
  message,
  Form,
  Input,
  Button,
  Tooltip,
  Row,
  Col,
  Alert,
  Radio,
  ConfigProvider,
  Table,
  Empty,
  Badge,
  Tabs,
  Select,
  DatePicker,
  Spin,
  Switch,
  Popconfirm,
  Modal,
  Upload,
  Menu,
  Tag,
  Divider,
  Progress,
  Card,
  InputNumber,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import api from './http/api';
import http from './http/http';
import './http/axios';
import moment from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import VChart from 'vue-echarts';

const app = createApp(App)
  .use(router)
  .use(store)
  .use(Form)
  .use(Input)
  .use(Tooltip)
  .use(Row)
  .use(Col)
  .use(Alert)
  .use(Radio)
  .use(Table)
  .use(ConfigProvider)
  .use(Empty)
  .use(Badge)
  .use(Tabs)
  .use(Select)
  .use(DatePicker)
  .use(Spin)
  .use(Switch)
  .use(Popconfirm)
  .use(Modal)
  .use(Menu)
  .use(Upload)
  .use(Divider)
  .use(Tag)
  .use(Progress)
  .use(Card)
  .use(Button)
  .use(InputNumber)
  .component('v-chart', VChart);

app.config.globalProperties.$message = message;
app.config.globalProperties.$api = api;
app.config.globalProperties.$http = http;

moment.extend(isBetween);
moment.extend(utc);
app.config.globalProperties.$moment = moment;

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue: app,
    dist,
    release,
    dsn: 'https://35a0b49e5307446ea45f543fabb122ae@o400567.ingest.sentry.io/5626771',
  });
}

app.mount('#app');
